.navbar .collapsing .navbar-toggler {
  pointer-events: none;
}
.navbar .nav-item .nav-link-icon.nav-link i {
  margin-right: 4px;
}

.navbar-small {
  .navbar-collapse {
    &:before {
      margin-top: 8px !important;
    }
  }
}
