.btn-custom {
  width: 24px;
  height: 32px;
}

.btn-custom:hover {
  background-color: #bcc0c5;
  border-color: #bcc0c5;
}

/* Estilizando o scroll */
::-webkit-scrollbar {
  width: 8px;
}

/* Estilizando a barra do scroll */
::-webkit-scrollbar-thumb {
  background-color: #9da0a3;
  border-radius: 8px;
}

/* Estilizando a área do scroll */
::-webkit-scrollbar-track {
  background-color: #f8f9fa;
}

.btn-hover:hover{
  filter: brightness(.9);
  transition: all 0.2s;
}

.btn-hover.active:hover{
  filter: brightness(1.1);
  transition: all 0.2s;
}