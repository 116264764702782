.calendar-app td {
  vertical-align: middle !important;
}
.fc {
  text-align: center !important;
}

.fc td {
  padding: 0;
  vertical-align: middle !important;
}
.fc-day:first-child > div:first-child {
  position: relative;
}

.fc-day:first-child > div:first-child .fc-day-number {
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  text-align: center;
  margin-top: -0.5em;
  line-height: 1em;
}
