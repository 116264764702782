.btn-table-row {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 0;
  background: transparent;
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 20px 35px;
}
